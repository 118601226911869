<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Atelier portage physiologique</h1>
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    Futurs ou jeunes parents, vous souhaitez porter votre bébé
                    mais vous ne savez pas quel moyen de portage choisir ? vous
                    avez déjà du matériel mais vous ne savez pas l’utiliser ?
                    Vous voulez vous perfectionner ou apprendre de nouveaux
                    nouages ?<br />
                    <br />
                    Je vous propose des ateliers de portage à domicile, je me
                    déplace avec mes poupons et mon matériel afin de vous
                    permettre de tester différents moyens de portage. C’est un
                    moment convivial et bienveillant où toutes les questions
                    sont permises, il se déroulera au rythme de bébé.<br />
                    <br />
                    Un atelier est possible en fin de grossesse pour vous aider
                    à faire votre choix et pour porter votre bébé dès la
                    naissance.<br />
                    <br />
                    Parfois, lorsque nous faisons un atelier en fin de grossesse,
                    vous avez besoin d'un petit rappel pour vous rassurer et 
                    porter votre bébé dans les meilleurs conditions possibles. 
                    Pour cela, j'ai créé <b-link to="/packs">le pack Koala</b-link>.
                    <br />
                    <price :price="70" :group="true" />
                </p>
            </b-col>
            <b-col xs="12" lg="6">
                <non-clickable-img url="babywearing.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Portage',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
h1 {
    line-height: 150%;
}

p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
